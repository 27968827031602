import axios from 'axios';
import moment from 'moment-timezone';
import { getShortTimezoneText } from 'helpers/getTimezoneText';

export default async function notifyViaTwilio(
    apptToConfirm,
    customTwilioNumUS,
    firstname,
    lastname,
    toPhoneNumber,
    studioName,
    customerServicePhone,
    timezone,
    dibsId,
    thislanguage,
    customerServiceInfo,
    userid,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    try {
        const basicUrl = `${baseURL}/twilio/notify`;
        const starttime = apptToConfirm.start_time;
        const dateinUTC = moment.utc(starttime);
        let formattedDate = dateinUTC.format('M/D/YY');
        let formattedTime = dateinUTC.format('h:mm a');
        if (thislanguage === 'it') {
            formattedDate = dateinUTC.format('D/M/YY');
            formattedTime = dateinUTC.format('H:mm');
        }
        const shortTimeZone = getShortTimezoneText(timezone);
        let signature;
        let addresstoadd;

        const {
            custServAddress,
            custServCity,
            custServState,
            custServZip,
            custServAddress2,
        } = customerServiceInfo;

        if (dibsId === '226' || dibsId === '260') {
            signature = '-BARBER PISTERZI';
        }

        if (custServAddress) {
            let firstpartaddresstext = 'As a reminder, the address is: ';
            if (thislanguage === 'it') {
                firstpartaddresstext = "Come promemoria, l'indirizzo è: ";
            }
            let addresstwo = '';
            if (custServAddress2) {
                addresstwo =
                    custServAddress2.length > 1 ? ` ${custServAddress2}` : '';
            }
            addresstoadd = `${firstpartaddresstext}${custServAddress}${addresstwo}, ${custServCity}, ${custServState} ${custServZip}`;
        }
        // format the data and start time
        let message = `Your appointment at ${studioName} is confirmed.  We look forward to seeing you on ${formattedDate} at ${formattedTime} ${shortTimeZone}.  If you have any questions, please call us at ${customerServicePhone}.  Thank you!  ${signature}\n\n${addresstoadd}`;
        if (thislanguage === 'it') {
            message = `Il tuo appuntamento presso ${studioName} è confermato. Non vediamo l'ora di vederti il ${formattedDate} alle ${formattedTime} ${shortTimeZone}. Se hai domande, chiamaci al ${customerServicePhone}. Grazie! ${signature}\n\n${addresstoadd}`;
        }

        const twiliodata = {
            phoneNumberTo: toPhoneNumber,
            customTwilioNumUS,
            twilioMsg: message,
            dibsId,
            userid,
        };
        await axios.post(basicUrl, twiliodata);
        return 1;
    } catch (err) {
        console.log('error in notifyViaTwilio is', err);
        return 0;
    }
}
