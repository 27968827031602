import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function confirmNoConflictBeforeBooking(data) {
    try {
        const url = getApiUrl('/appts/confirm-no-conflict');
        const res = await axios.post(url, data);
        console.log('\n\n\nresponse from the back end is', res.data);
        return res.data;
    } catch (err) {
        console.log('Error in confirmNoConflictBeforeBooking', err);
        return err;
    }
}
