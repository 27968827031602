import React, { useEffect, useState } from 'react';
import { Grid, Typography, Fade, Checkbox, Button } from '@mui/material';
// import Typography from '@mui/joy/Typography';
import { alpha } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import parse from 'html-react-parser';

import { motion } from 'framer-motion';
import { useSelector, dispatch } from 'store';
import {
    setSelectedId,
    setSelectedStaff,
    setSelectedIdsMulti,
    setMultiModeOn,
    removeSelectedIdsMulti,
    setApptIsConfirmed, // last thing added
} from 'store/slices/appointments';
import DotIcon from 'assets/icons/dotIcon';
import StaffList from 'components/appointment-types/staff/staffList';
import CalendarBox from './datetimecalendar/calendarIndex';
import configs from 'helpers/specialConfigs';
import useTranslate from 'hooks/useTranslate';
import { capitalizeEntireString } from 'helpers';

const ApptItem = ({ appt, isLast }) => {
    const translate = useTranslate();
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const { dibsStudioId, currency } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const colortoshow = `#${color}`;
    const [currencySymbol, setCurrencySymbol] = useState('$');
    useEffect(() => {
        if (currency === 'USD') setCurrencySymbol('$');
        else if (currency === 'EUR') setCurrencySymbol('€');
    }, [currency]);

    const [isChoosingProvider, setIsChoosingProvider] = React.useState(false);
    const { selectedId, selectedStaff, selectedIdsMulti, multiModeOn } =
        useSelector((state) => state.appointments);
    // const [multiModeOn, setMultiModeOn] = React.useState(false);

    const [isShowMore, setIsShowMore] = React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [timeToChooseDate, setTimeToChooseDate] = React.useState(false);
    const minuteText = translate('minutes', 'minutes');
    const moreInfoTxt = translate('more-info', 'More Info');
    const lessInfoTxt = translate('less-info', 'Less Info');
    const nextTxt = translate('next', 'Next');
    const providerTxt = translate('provider', 'Provider');
    const nextTxtCapitalize = capitalizeEntireString(nextTxt);
    React.useEffect(() => {
        if (selectedId === appt.id) {
            setChecked(true);
        } else if (selectedIdsMulti.includes(appt.id)) {
            setChecked(true);
        } else if (!selectedIdsMulti.includes(appt.id)) {
            setChecked(false);
        }
        if (selectedStaff !== null) {
            setTimeToChooseDate(true);
        } else {
            setTimeToChooseDate(false);
        }
    }, [appt.id, selectedId, selectedIdsMulti, selectedStaff]);

    const nameOfAppt = appt.appointment_type;
    let formattedName = nameOfAppt.toUpperCase();
    let secondPartOfName = '';
    if (dibsStudioId === '226' || dibsStudioId === '260') {
        const searchFor = '- ';
        const indexQuote = formattedName.search(searchFor);
        if (indexQuote > 0) {
            // splitting english + italian
            secondPartOfName = formattedName.slice(indexQuote);
            formattedName = formattedName.slice(0, indexQuote);
        }
    }

    const showmore = () => {
        setIsShowMore(!isShowMore);
    };
    const selectThisType = () => {
        console.log('select this type clicked');
        if (!checked) {
            dispatch(setSelectedId(appt.id));
            dispatch(setSelectedIdsMulti(appt.id));
        }
        setChecked(!checked);
        // if (checked) {
        //     dispatch(setSelectedId(null));
        //     dispatch(setSelectedStaff(null));
        //     dispatch(removeSelectedIdsMulti(appt.id));
        // }
    };
    const unselectThisProvider = () => {
        dispatch(setSelectedId(null));
        setIsChoosingProvider(false);
        dispatch(setSelectedStaff(null));
        setTimeToChooseDate(false);
    };
    const checkoruncheck = () => {
        setChecked(!checked);
        if (checked) {
            dispatch(setSelectedId(null));
            setIsChoosingProvider(false);
            dispatch(setSelectedStaff(null));
            setTimeToChooseDate(false);
            dispatch(removeSelectedIdsMulti(appt.id));
        }
        if (!checked) {
            dispatch(setSelectedId(appt.id));
            dispatch(setSelectedIdsMulti(appt.id));
            window.scrollTo({
                top: 100,
                left: 0,
                behavior: 'smooth',
            });
        }
        setTimeout(() => {
            dispatch(setSelectedId(appt.id));
            dispatch(setSelectedIdsMulti(appt.id));
            dispatch(setApptIsConfirmed(false));
        }, 100);
        setTimeToChooseDate(false);
    };
    const goBackTextAction = () => {
        setTimeToChooseDate(false);
        setIsChoosingProvider(true);
    };
    const addMultiMode = () => {
        console.log('multi mode entered');
        // setMultiModeOn(true);
        dispatch(setMultiModeOn(true));
        dispatch(setSelectedIdsMulti(appt.id));
    };
    const giveOptionChooseStaffMember = () => {
        setIsChoosingProvider(true);
        setTimeToChooseDate(false);
    };
    const transparent = alpha(colortoshow, 0.02);
    const specialBgColor = configs(dibsStudioId, 'bgColorAppts');
    let bgColorToUse = '#fff';
    if (specialBgColor !== undefined) {
        bgColorToUse = specialBgColor;
    }
    let borderColorToUse = '#d9d9d9';
    const specialBorderColor = configs(dibsStudioId, 'borderColorAppts');
    if (specialBorderColor !== undefined) {
        borderColorToUse = specialBorderColor;
    }
    let transparencyToUse = transparent;
    const specialTransparency = configs(dibsStudioId, 'transparencyAppts');
    if (specialTransparency !== undefined) {
        transparencyToUse = specialTransparency;
    }
    // const moreInfoText = parse(appt.description);
    //     const moreInfoText = marked.parse(
    //         `PERFECT FOR THOSE WHO WANT AN IMPECCABLE STYLE. <br>IT INCLUDES ONE TAILORED HAIR CUT AND ONE NECK
    // AND SIDEBURNS CLEAN UP PER MONTH FOR 12 MONTHS AT OUR ATELIER IN NEW YORK
    // THE MEMBERSHIP INCLUDES A 15% DISCOUNT ON PRODUCT PURCHASES AT THE ATELIER AND ONLINE AT
    // WWW.BARBERPISTERZI.COM`,
    //     );

    const moreInfoText = parse(appt.description);

    return (
        <Slide direction="down" in mountOnEnter unmountOnExit timeout={450}>
            <Grid
                item
                xs={12}
                sx={{ mt: 2.5 }}
                border={0}
                id="grid-item-appointment">
                <Grid
                    container
                    id={appt.id}
                    alignItems="flex-start"
                    border={0}
                    // sx={{ mx: 3 }}
                    justifyContent="space-evenly">
                    {/* time grid */}
                    <Grid
                        item
                        id="appt-item-grid"
                        sx={{ mx: window.innerWidth < 650 ? 0 : 5 }}
                        backgroundColor={
                            checked ? transparencyToUse : bgColorToUse
                        }
                        border={0}
                        xs={12}
                        md={10.5}
                        lg={10}>
                        <motion.div
                            initial={{ opacity: 0.8, scale: 1 }}
                            animate={{ opacity: 1, scale: 1 }}
                            whileTap={{
                                scale: 1.1,
                                // transition: { duration: 1 },
                            }}
                            // onTap={onTap}
                            transition={{
                                duration: 200,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                    type: 'spring',
                                    damping: 5,
                                    stiffness: 50,
                                    restDelta: 0.01,
                                },
                            }}>
                            <Grid
                                container
                                // exit={{
                                //     y: window.innerHeight,
                                //     transition: { duration: 200 },
                                // }}
                                border={0}
                                id="appointment-item-container-individual-items">
                                <Grid
                                    item
                                    id="item-holding-appt-option"
                                    border={1}
                                    borderRadius={1.2}
                                    borderColor={
                                        checked ? colortoshow : borderColorToUse
                                    }
                                    xs={12}
                                    sx={{
                                        p: 2.25,
                                        '&:hover': {
                                            borderColor: colortoshow,
                                        },
                                    }}>
                                    <Grid
                                        container
                                        border={0}
                                        id="appointment-item-container-down-one-item"
                                        sx={{
                                            pl: window.isTiny ? 0 : 0.75,
                                            pt: window.isTiny ? 0 : 0.5,
                                        }}>
                                        <Grid
                                            item
                                            id="appointment-title-container"
                                            xs={10}
                                            sx={{ mb: 1 }}
                                            border={0}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    id="appt-title-name-formatted">
                                                    <Typography
                                                        variant="apptTitle"
                                                        sx={{
                                                            fontWeight:
                                                                dibsStudioId ===
                                                                    '226' ||
                                                                dibsStudioId ===
                                                                    '260'
                                                                    ? 700
                                                                    : 400,
                                                        }}>
                                                        {formattedName}
                                                    </Typography>
                                                    {(dibsStudioId === '226' ||
                                                        dibsStudioId ===
                                                            '260') && (
                                                        <Typography variant="apptTitle">
                                                            {secondPartOfName}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    id="typography-text-check-appt"
                                                    xs={12}
                                                    sx={{ pt: 0.6 }}>
                                                    {/* <Typography fontFamily="Roboto"> */}
                                                    <Typography>
                                                        <Typography variant="apptSubUpdated">
                                                            {appt.default_price >
                                                            0
                                                                ? currencySymbol
                                                                : ''}
                                                            {appt.default_price >
                                                            0
                                                                ? appt.default_price
                                                                : ''}{' '}
                                                            {appt.default_price >
                                                            0 ? (
                                                                <DotIcon />
                                                            ) : (
                                                                ''
                                                            )}{' '}
                                                            {
                                                                appt.length_minutes
                                                            }{' '}
                                                            {minuteText}
                                                        </Typography>
                                                        {appt.description !==
                                                            null && (
                                                            <Typography
                                                                display="inline"
                                                                color={
                                                                    colortoshow
                                                                }
                                                                component="span"
                                                                onClick={
                                                                    showmore
                                                                }
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                variant="apptMoreInfo">
                                                                <DotIcon />{' '}
                                                                <span
                                                                    style={{
                                                                        color: colortoshow,
                                                                    }}>
                                                                    {isShowMore
                                                                        ? lessInfoTxt
                                                                        : moreInfoTxt}
                                                                </span>
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                {isShowMore && (
                                                    <Fade in timeout={600}>
                                                        <Grid
                                                            item
                                                            xs={
                                                                window.isTiny
                                                                    ? 11
                                                                    : 9
                                                            }
                                                            sx={{
                                                                pt: 2,
                                                                mr: 5,
                                                                mb: 2,
                                                            }}>
                                                            <Grid
                                                                container
                                                                flex
                                                                align="justify">
                                                                <Typography variant="apptMoreInfoText">
                                                                    {
                                                                        moreInfoText
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Fade>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} sx={{ mb: 1 }}>
                                            <Grid
                                                container
                                                border={0}
                                                justifyContent="space-around"
                                                alignItems="flex-start">
                                                <Grid
                                                    item
                                                    id="checkbox-item"
                                                    border={0}
                                                    sx={{ mt: 1 }}>
                                                    <Checkbox
                                                        inputProps={{
                                                            border: 5,
                                                        }}
                                                        checked={checked}
                                                        onChange={
                                                            checkoruncheck
                                                        }
                                                        sx={{
                                                            color: '#999',
                                                            '&.Mui-checked': {
                                                                color: colortoshow,
                                                            },
                                                            transform:
                                                                'scale(1.2)',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {checked && (
                                            <Fade in timeout={950}>
                                                <Grid item xs={12} border={0}>
                                                    {!isChoosingProvider &&
                                                        selectedStaff ===
                                                            null && (
                                                            <Grid
                                                                container
                                                                justifyContent="center">
                                                                <Grid
                                                                    item
                                                                    xs={11}
                                                                    border={0}
                                                                    sx={{
                                                                        // pr: 3,
                                                                        mt: 1,
                                                                        pt: 2,
                                                                        pb: 1,
                                                                    }}>
                                                                    <Grid
                                                                        container
                                                                        justifyContent="flex-end">
                                                                        <Button
                                                                            variant="checkoutUpdated"
                                                                            type="submit"
                                                                            onClick={
                                                                                giveOptionChooseStaffMember
                                                                            }>
                                                                            {
                                                                                nextTxtCapitalize
                                                                            }
                                                                            :
                                                                            Choose
                                                                            A
                                                                            Staff
                                                                            Member
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid
                                                                        border={
                                                                            0
                                                                        }
                                                                        container
                                                                        justifyContent="flex-end"
                                                                        sx={{
                                                                            mt: 1,
                                                                            pr: 0.5,
                                                                        }}>
                                                                        <Button
                                                                            variant="text"
                                                                            onClick={
                                                                                addMultiMode
                                                                            }>
                                                                            {' '}
                                                                            + Or
                                                                            Add
                                                                            more
                                                                            services
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    {!isChoosingProvider &&
                                                        selectedStaff !==
                                                            null && (
                                                            <Fade
                                                                in={checked}
                                                                timeout={600}>
                                                                <Grid
                                                                    item
                                                                    xs={12}>
                                                                    <Typography variant="providerUpdated">
                                                                        {
                                                                            providerTxt
                                                                        }
                                                                        :{' '}
                                                                        {
                                                                            selectedStaff.firstname
                                                                        }{' '}
                                                                        {
                                                                            selectedStaff.lastname
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="eventwithcolor"
                                                                        onClick={() =>
                                                                            unselectThisProvider()
                                                                        }>
                                                                        {'   '}
                                                                        edit
                                                                    </Typography>
                                                                </Grid>
                                                            </Fade>
                                                        )}
                                                    {isChoosingProvider && (
                                                        <Fade
                                                            in={checked}
                                                            timeout={600}>
                                                            <Grid item xs={12}>
                                                                <StaffList
                                                                    setIsChoosingProvider={
                                                                        setIsChoosingProvider
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Fade>
                                                    )}
                                                    {timeToChooseDate && (
                                                        <Fade
                                                            in={checked}
                                                            timeout={600}>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                id="item-holding-calendar-box"
                                                                sx={{ mt: 1 }}
                                                                border={0}>
                                                                <CalendarBox
                                                                    gobackaction={
                                                                        goBackTextAction
                                                                    }
                                                                    setTimeToChooseDate={
                                                                        setTimeToChooseDate
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Fade>
                                                    )}
                                                </Grid>
                                            </Fade>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    );
};

export default ApptItem;
